import React from "react"
import * as styles from "./AIExpertiseSection.module.scss"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"

const AIExpertiseSection = ({ strapiData }) => {
  return (
    <Container className={styles.sectionContainer}>
      <Row>
        <Col xl={4} lg={12} md={12} sm={12} xs={12}>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          />
        </Col>

        <Col xl={5} lg={6} md={12} sm={12} xs={12}>
          <p className={styles.description}>{strapiData?.subTitle}</p>
        </Col>
        <Col xl={3} lg={6} md={12} sm={12} xs={12}>
          {strapiData?.buttons.length > 0 && (
            <Link to={strapiData?.buttons[0]?.url}>
              <div className={styles.blueButton} style={{ color: "#010101" }}>
                {strapiData?.buttons?.[0] && (
                  <p style={{ color: "#010101" }}>
                    {strapiData?.buttons?.[0].title}
                  </p>
                )}
              </div>
            </Link>
          )}
        </Col>
      </Row>
      <Row className={styles.cardsContainer}>
        {strapiData?.cards?.map((el, ind) => (
          <Col
            xl={6}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            key={`expertise_${ind}_${el?.title}`}
            className="d-flex"
          >
            <div className={styles.card}>
              <Row className={styles.card1}>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <div className={styles.cardCounting}>
                    <p>{`0${ind + 1}`}</p>
                  </div>
                </Col>
                <Col
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  className={styles.imgContainer}
                >
                  <img
                    src={el?.image1?.[0]?.localFile?.publicURL}
                    className={styles.cardImage}
                    alt="cardImg"
                  />
                </Col>
              </Row>
              <h3 className={styles.cardHeading}>{el?.title}</h3>
              <p className={styles.cardDesp}>{el?.subTitle}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default AIExpertiseSection
