// extracted by mini-css-extract-plugin
export var card = "ComputerVision-module--card--75c16";
export var cardCol = "ComputerVision-module--cardCol--10b16";
export var cardCounting = "ComputerVision-module--cardCounting--76890";
export var cardHeading = "ComputerVision-module--cardHeading--c16d0";
export var cardsContainer = "ComputerVision-module--cardsContainer--85c6c";
export var description = "ComputerVision-module--description--12f07";
export var highlight = "ComputerVision-module--highlight--0cbdc";
export var imgContainer = "ComputerVision-module--imgContainer--252e1";
export var sectionContainer = "ComputerVision-module--sectionContainer--07d3c";
export var subTitle = "ComputerVision-module--subTitle--5dfc1";