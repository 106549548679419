import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData, custome }) => {
  return (
    <div className={`${styles.computerVisionBanner}`}>
      <Container>
        <Row>
          <Col xl={8} lg={12} md={12}>
            <div className={styles.heading}>
              <h1
                className={`${styles.mainTitle} ${
                  custome ? styles.customeHead : ""
                }`}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />

              <h2
                className={`${styles.bannerHeading} `}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
              <p
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
            <div className={styles.btnsOuter}>
              <div className={styles.cardFooter}>
                <Link
                  to={strapiData?.buttons[0]?.url}
                  className="computerVisionButton"
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              </div>
              {strapiData?.buttons?.length > 1 &&
                strapiData?.buttons[1]?.title && (
                  <div className={styles.cardFooter}>
                    <Link
                      className={"computerVisionButton"}
                      to={strapiData?.buttons[1]?.url}
                    >
                      {strapiData?.buttons[1]?.title}
                    </Link>
                  </div>
                )}
            </div>
          </Col>
          <Col xl={6} lg={12} md={12}></Col>
        </Row>
      </Container>
      <img
        className={styles.curve}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
        alt="curve"
      />
    </div>
  )
}

export default Banner
