// extracted by mini-css-extract-plugin
export var arrowAnimation = "AIExpertiseSection-module--arrowAnimation--6e457";
export var blueButton = "AIExpertiseSection-module--blueButton--fc7d5";
export var card = "AIExpertiseSection-module--card--1799c";
export var cardCounting = "AIExpertiseSection-module--cardCounting--2f907";
export var cardDesp = "AIExpertiseSection-module--cardDesp--a9646";
export var cardHeading = "AIExpertiseSection-module--cardHeading--1cadb";
export var cardImage = "AIExpertiseSection-module--cardImage--aec57";
export var cardsContainer = "AIExpertiseSection-module--cardsContainer--ee677";
export var cta = "AIExpertiseSection-module--cta--eccde";
export var description = "AIExpertiseSection-module--description--36c32";
export var heading = "AIExpertiseSection-module--heading--e67b9";
export var highlight = "AIExpertiseSection-module--highlight--297f5";
export var imgContainer = "AIExpertiseSection-module--imgContainer--fc171";
export var sectionContainer = "AIExpertiseSection-module--sectionContainer--03419";