// extracted by mini-css-extract-plugin
export var bannerHeading = "Banner-module--bannerHeading--09f7a";
export var btnsOuter = "Banner-module--btnsOuter--9cf90";
export var cardFooter = "Banner-module--cardFooter--d3793";
export var computerVisionBanner = "Banner-module--computerVisionBanner--1e2f7";
export var computerVisionButton = "Banner-module--computerVisionButton--467a9";
export var curve = "Banner-module--curve--4d9d6";
export var customeHead = "Banner-module--customeHead--96c4b";
export var description = "Banner-module--description--5ee83";
export var heading = "Banner-module--heading--711d2";
export var mainTitle = "Banner-module--mainTitle--d2eea";