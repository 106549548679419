// extracted by mini-css-extract-plugin
export var Trust = "BusinessUsecase-module--Trust--57592";
export var arrowIcon = "BusinessUsecase-module--arrowIcon--d5ab9";
export var arrowImage = "BusinessUsecase-module--arrowImage--ed9aa";
export var arrowLink = "BusinessUsecase-module--arrowLink--19ea5";
export var btn = "BusinessUsecase-module--btn--fc8ef";
export var btn2 = "BusinessUsecase-module--btn2--34174";
export var caseImages = "BusinessUsecase-module--caseImages--d8c96";
export var clientImgs = "BusinessUsecase-module--clientImgs--250d2";
export var content = "BusinessUsecase-module--content--42541";
export var description = "BusinessUsecase-module--description--605b1";
export var headSearch = "BusinessUsecase-module--headSearch--3b99a";
export var heading = "BusinessUsecase-module--heading--e555c";
export var logoImages = "BusinessUsecase-module--logoImages--24543";
export var logos = "BusinessUsecase-module--logos--8f6e4";
export var rowGap = "BusinessUsecase-module--rowGap--af957";
export var talkBtn = "BusinessUsecase-module--talkBtn--115c0";