// extracted by mini-css-extract-plugin
export var aiPoweredCard = "AIPowered-module--aiPoweredCard--530cb";
export var cardContent = "AIPowered-module--cardContent--f8758";
export var cardDescription = "AIPowered-module--cardDescription--f738f";
export var cardHeading = "AIPowered-module--cardHeading--636e5";
export var counting = "AIPowered-module--counting--a6a95";
export var desc = "AIPowered-module--desc--b8747";
export var headingContainer = "AIPowered-module--headingContainer--4b6e7";
export var keyPoint = "AIPowered-module--keyPoint--f21c0";
export var keyPoints = "AIPowered-module--keyPoints--cbb8d";
export var keyPointsCard = "AIPowered-module--keyPointsCard--e21d5";
export var sectionContainer = "AIPowered-module--sectionContainer--dc425";
export var subTitle = "AIPowered-module--subTitle--b60e1";
export var title = "AIPowered-module--title--59e1f";