import React, { useEffect, useRef, useState } from "react"
import * as styles from "./WordsFromClients.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Modal from "react-bootstrap/Modal"
import ReactPlayer from "react-player"
import Slider from "react-slick"

function Arrow(props) {
  const { onClick, currentSlide, slideCount, sliderRef } = props;
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={() => {
        if (currentSlide === slideCount - 1) {
          sliderRef?.slickGoTo(0); // Reset to first slide
        } else {
          onClick(); // Move to next slide
        }
      }}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/right_f5df90e3bd.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  );
}

function PrevArrow(props) {
  const { onClick, currentSlide, slideCount, sliderRef } = props;
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={() => {
        if (currentSlide === 0) {
          sliderRef?.slickGoTo(slideCount - 1); // Go to last slide if at the first
        } else {
          onClick(); // Move to previous slide
        }
      }}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/left_ccefba5791.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  );
}

const WordsFromClients = ({ strapiData, projectEngRTest }) => {
  const [showModal, setShowModal] = useState(false)
  const [currentVideo, setCurrentVideo] = useState("")

  const data = [
    {
      name: "David",
      nameHighlight: "Smith", // Separate the highlighted part
      designation: "CEO & Co-Founder - Easyfill",
      dec: "InvoZone has been a game-changer for us, offering fresh ideas and genuine support. This wasn’t our first outsourcing experience, but it has definitely been the smoothest.",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19829_81dcf601de.webp",
      logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/Easyfill_27b51f4ed8.png",
      video: "https://www.youtube.com/watch?v=QGxTand7ld4",
    },
    {
      nameHighlight: "Wolff", // Separate the highlighted part
      name: "Oliver ",
      designation: "Kindle Product Manager",
      dec: "We partnered with InvoZone to enhance our Elixir SDK, and the process was seamless. Consistent communication and timely status updates, even with minor estimate shifts, allowed us to adapt efficiently. Their proactive approach kept us informed, and the code quality exceeded our expectations, reducing extensive testing. Overall, InvoZone's clear communication and high-quality delivery made them a standout partner, ensuring smooth collaboration throughout the project.",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19829_6b12274dee.png",
      logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/kinde_ca9fa25ebc.png",
      video: "https://www.youtube.com/watch?v=f4ux_gpczhs",
    },
    {
      nameHighlight: "Zabel", // Separate the highlighted part
      name: "Ron",
      designation: "CEO - Crytool.io",
      dec: "InvoZone has been a game-changer for us, bringing fresh ideas and genuine support to our projects. This was not the first time we outsourced, but for sure the smoothest experience we had.",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19829_1_77a08bad66.png",
      logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/image_21_0b4dd18653.png",
      video: "https://www.youtube.com/watch?v=aOw4IfvEng4",
    },
    {
      nameHighlight: "Carter", // Separate the highlighted part
      name: "Ryan ",
      designation: "Co Founder - Shield Republic",
      dec: "We were initially hesitant to outsource overseas, especially after our previous experience with another company that provided average services. Since partnering with InvoZone, we've seen no decline in quality. The team is responsive, easy to work with, and possesses comprehensive knowledge of web app development and API integrations.",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/ryan_98b623518c.webp",
      logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/shield_republic_c0730815cc.png",
      video: "https://www.youtube.com/watch?v=ETvJcozwwzM",
    },
    {
      nameHighlight: "Dominguez", // Separate the highlighted part
      name: "Chris ",
      designation: "CEO - StorageChain",
      dec: "Every member of the InvoZone team has been diligent and focused, fostering a genuine partnership on the technology front.",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Chris_Dominguez_8fb4bfa290.webp",
      logo: "https://invozone-backend.s3.us-east-1.amazonaws.com/image_removebg_preview_48_1_65fe824baf.png",
      video: "https://www.youtube.com/watch?v=SeBRvwbCuks",
    },
  ]

  const logoData = [
    {
      img1: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_2_64c502a23f.png",
    },
    {
      img1: "https://invozone-backend.s3.us-east-1.amazonaws.com/4j_LL_68_1cf139b098.png",
    },
    {
      img1: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1720e8c973.png",
    },
    {
      img1: "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1_d29ba30c66.png",
    },
    {
      img1: "https://invozone-backend.s3.us-east-1.amazonaws.com/Services_Categories_e5e200215f.png",
    },
  ]

  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)
  const [current_3, setCurrent_3] = useState(0)

  useEffect(() => {
    if (
      current === strapiData?.cards.length - 4 ||
      current - 1 === strapiData?.cards.length - 3 ||
      current_2 === strapiData?.cards.length - 2 ||
      current_3 === strapiData?.cards.length - 1
    ) {
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 3000)
    }
  }, [current, current_1, current_2, current_3])
  const settings = {
    autoplay: true,
    dots: false,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    prevArrow: <PrevArrow currentSlide={current} slideCount={data.length} sliderRef={slide1} />,
    nextArrow: <Arrow currentSlide={current} slideCount={data.length} sliderRef={slide1} />,
    infinite: false,
    speed: 200,
    afterChange: (current) => {
      setCurrent(current); // Update current slide index
      if (current === data.length - 1) {
        setTimeout(() => {
          slide1?.current?.slickGoTo(0); // Reset to first slide after reaching the last slide
        }, 3000); // Delay before resetting
      }
    },
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1140,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
    ],
  }

  const handleVideoPlay = videoUrl => {
    setCurrentVideo(videoUrl)
    setShowModal(true)
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <Row className={styles.headingContainer}>
            <Col xs={12} sm={12} md={strapiData?.subTitle ? 6 : 10} lg={strapiData?.subTitle ? 4 : 10}>
              <h2 className={styles.heading}
                 dangerouslySetInnerHTML={{
                        __html: strapiData?.title,
                    }}
              >
              </h2>
            </Col>
            {strapiData?.subTitle && <Col xs={12} sm={12} md={5} lg={6}>
            <p className={styles.description}>{strapiData?.subTitle}</p>
            </Col>}
          </Row>

          <Slider {...settings} className={styles.SliderWidth} ref={slide1}>
            {data?.map((el, i) => (
              <div className={styles.clientContent} key={i}>
                <Row className="align-items-center justify-content-center gap-30">
                  <Col lg={5}>
                    <div>
                      <img src={el?.image2} alt={el?.name} />
                      <button
                        className={styles.playBtn}
                        onClick={() => handleVideoPlay(el.video)}
                      >
                        Play Video
                      </button>
                    </div>
                  </Col>
                  <Col lg={5}>
                    <div className={styles.content}>
                      <img src={el?.logo} />
                      <h3 className={styles.name}>
                        {el?.name}{" "}
                        <span className={styles.blueText}>
                          {el?.nameHighlight}
                        </span>
                      </h3>
                      <p className={styles.designation}>{el?.designation}</p>
                      <p className={styles.dec}>{el?.dec}</p>
                      <p className={styles.view}>View Case Study</p>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </Slider>
          <img
            src="https://invozone-backend.s3.us-east-1.amazonaws.com/_d9008f0e82.png"
            decoding="async"
            loading="lazy"
            className={styles.coma}
          />
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            centered
            size="lg"
            className={styles.customModal}
          >
            <Modal.Body style={{ minHeight: "unset", padding: "1rem" }}>
              <ReactPlayer
                url={currentVideo}
                width="100%"
                height="400px"
                controls
              />
            </Modal.Body>
          </Modal>
        </Container>

      </React.Fragment>
    </div>
  )
}

export default WordsFromClients
