// extracted by mini-css-extract-plugin
export var arrowAnimation = "LongTerm-module--arrowAnimation--e6256";
export var black = "LongTerm-module--black--b3bd9";
export var blue = "LongTerm-module--blue--57a65";
export var card = "LongTerm-module--card--d564b";
export var card1 = "LongTerm-module--card1--3cd2d";
export var card2 = "LongTerm-module--card2--9f0bb";
export var card3 = "LongTerm-module--card3--5f596";
export var colGap = "LongTerm-module--colGap--1f321";
export var concerns = "LongTerm-module--concerns--31935";
export var description = "LongTerm-module--description--6b168";
export var heading = "LongTerm-module--heading--8bb2c";
export var newBtn = "LongTerm-module--newBtn--26c84";
export var progress = "LongTerm-module--progress--7fe55";
export var progressSec = "LongTerm-module--progressSec--4ee90";
export var subDescription = "LongTerm-module--subDescription--3c113";
export var subHeading = "LongTerm-module--subHeading--38c21";
export var talkBtn = "LongTerm-module--talkBtn--0d265";
export var white = "LongTerm-module--white--fa694";