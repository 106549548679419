// extracted by mini-css-extract-plugin
export var SliderWidth = "WordsFromClients-module--SliderWidth--e505e";
export var Trust = "WordsFromClients-module--Trust--08a40";
export var blueText = "WordsFromClients-module--blueText--156fb";
export var clientContent = "WordsFromClients-module--clientContent--6bf6b";
export var coma = "WordsFromClients-module--coma--0041a";
export var content = "WordsFromClients-module--content--2b806";
export var customModal = "WordsFromClients-module--customModal--44f40";
export var dec = "WordsFromClients-module--dec--ebfb1";
export var description = "WordsFromClients-module--description--c24fe";
export var designation = "WordsFromClients-module--designation--3d0a6";
export var dot = "WordsFromClients-module--dot--67353";
export var heading = "WordsFromClients-module--heading--1f6b4";
export var headingContainer = "WordsFromClients-module--headingContainer--52aa6";
export var iconContainer = "WordsFromClients-module--iconContainer--59f61";
export var iconContainerLeft = "WordsFromClients-module--iconContainerLeft--5a42c";
export var logos = "WordsFromClients-module--logos--12e54";
export var logosBg = "WordsFromClients-module--logosBg--e39ce";
export var name = "WordsFromClients-module--name--9dd4d";
export var playBtn = "WordsFromClients-module--playBtn--cdce7";
export var portfolioArrowIcon = "WordsFromClients-module--portfolioArrowIcon--9a8d0";
export var portfolioArrowIconCover = "WordsFromClients-module--portfolioArrowIconCover--bc586";
export var portfolioArrowRightIconCover = "WordsFromClients-module--portfolioArrowRightIconCover--c1567";
export var view = "WordsFromClients-module--view--18417";